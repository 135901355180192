import React, { useState, useEffect, useCallback } from "react"
import differenceInSeconds from "date-fns/differenceInSeconds"

export default ({ startDate }) => {
  const [timeElapsed, setTimeElapsed] = useState("--:--")

  const zeroPad = (num, places) => String(num).padStart(places, "0")

  const getTimeElapsed = useCallback(() => {
    const dateFrom = new Date(startDate)
    const difference = differenceInSeconds(dateFrom, new Date())

    var seconds = 0;

    if (difference > -60){
      seconds = 60 + difference
      //console.log(difference);
    }
    else {
      seconds = 0;
    }

    return `${zeroPad(seconds)}`
  }, [startDate])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeElapsed(getTimeElapsed(startDate))
    }, 1000)

    return () => clearInterval(interval)
  }, [getTimeElapsed, startDate])

  return <>{timeElapsed}</>
}
