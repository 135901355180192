import React, { useState } from "react";
import cx from "classnames";
import ArrowBottom from "../../assets/images/arrow-bottom.png";

export default ({ message, time, answered, totalQuestions, answers, shoutout }) => {
  const zeroPad = (num, places) => String(num).padStart(places, "0")

  const [accordions, setAccordions] = useState([])
  return (
    <div className="test-page--results">
      <h1 className="tac">{message}</h1>

      <div>
        {/* <div className="test-page--results-title">Tavo rezultatai:</div>*/}
        <div className="test-page--results-stats" id="body">
            Tu ką tik laikei Europos egzaminą!<br /> 
          
            Tavo rezultatas <strong id="CTA">{answered}</strong>/{" "}<strong id="CTA">{totalQuestions}</strong> balų. Klausimus atsakei per <strong id="CTA">{time}</strong> <br />
            {shoutout}<br />
            
            Europos egzamino diplomą parsisiųsti gali <a href="https://europosegzaminas.lrt.lt/2024EEDiplomas.pdf" target="_blank" rel="noreferrer">čia</a>.<br />
            Kviečiame švęsti Europos dieną prisijungiant ir prie kitų iniciatyvų: <a href="https://lithuania.representation.ec.europa.eu/naujienos-ir-renginiai/europos-diena-2024_lt" target="_blank" rel="noreferrer">Europos dienos veiklos</a>
          <br />

        </div>
      </div>

      {!answers && (
        <div className="text-container">
          <p id="body">
            Sveikiname, sėkmingai sudalyvavai Europos egzamine! <br />
          </p>
        </div>
      )}

      {answers &&
        answers.map((answer, index) => {
          return (
            <div
              key={`answer_${index}`}
              className={cx("test-page--answers", {
                correct: answer.correct,
                open: accordions.indexOf(index) > -1,
              })}
            >
              <div
                role="button"
                tabIndex={0}
                className="test-page--answers-header"
                onClick={() => {
                  const newAccordions = [...accordions]

                  if (accordions.indexOf(index) > -1) {
                    newAccordions.splice(accordions.indexOf(index), 1)
                  } else {
                    newAccordions.push(index)
                  }

                  setAccordions(newAccordions)
                }}
              >
                <div className="test-page--answers-number">
                  {zeroPad(index + 1, 2)}

                  <img src={ArrowBottom} alt="" />
                </div>
                <div className="test-page--answers-question">
                  {answer.question}
                </div>

                <img src={ArrowBottom} alt="" />
              </div>

              <div className="test-page--answers-content">
                <div>
                  {answer.author && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: answer.author,
                    }}
                  ></span>
                  )}
                  <br/><br/>
                  {answer.choices.map((choice, qindex) => {
                    return (
                      <React.Fragment key={`answer_${index}_${qindex}`}>
                        <div
                          className={cx("test-page--answers-item", {
                            correct: choice.correct,
                            wrong: choice.selected && !choice.correct,
                          })}
                        >
                          {choice.answer}
                        </div>
                      </React.Fragment>
                    )
                  })}

                  {answer.metrics && answer.metrics.trim() !== "" && (
                    <div className="test-page--answers-note">
                      <span id="CTA2"
                        dangerouslySetInnerHTML={{
                          __html: answer.metrics,
                        }}
                      ></span>
                    </div>
                  )}
                </div>
                {/*answer.html && (
                  <div className="test-page--answers-content-assets">
                    {answer.html.map((asset, index) => {
                      return <img src={asset} alt="" key={index} />
                    })}
                  </div>
                )*/}
              </div>
            </div>
          )
        })}
    </div>
  )
}
