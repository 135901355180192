import React from "react"
import PropTypes from "prop-types"
import { WithErrorLabel } from "../FormError"
import Styles from "./Radio.module.scss"

const Radio = ({ children, field, ...props }) => (
  <div className={Styles.wrapper}>
    <label>
      <input {...field} {...props} type="radio" />
      <span>{children}</span>
    </label>
  </div>
)

Radio.propTypes = {
  children: PropTypes.any,
  field: PropTypes.object,
}

export default WithErrorLabel(Radio)
