import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Formik, Field } from "formik";
import { Link } from "gatsby";
// import axios from "axios";
import Checkbox from "../../Components/Checkbox";
import radio from "../../Components/Radio";
import Layout from "../../Components/Layout";
import TimeElapsed from "../../Components/TimeElapsed";
import Results from "../../Components/Results";
import Box from "../../Components/Box2";
import { ArrowToliau, ArrowAtgal } from "../../Components/Buttons";
import media2kl from "../../assets/images/2 kl..jpg";

export default () => {

  const startTime = new Date (Date.now()); 

  const timerBarIntRef = useRef();
  const timerBarIfRef = useRef();
  const timerStyleRef = useRef();

  const clearIntervals = () => {
    clearInterval(timerBarIntRef.current);
    clearInterval(timerBarIfRef.current);
    clearInterval(timerStyleRef.current);
  };

  useEffect(() => {
    let timerBar = 100;
    timerBarIntRef.current = setInterval(() => timerBar-=(100/60), 1000);
    //setInterval(() => console.log(timerBar.toFixed(3)), 1000);

    timerBarIfRef.current = setInterval(() => {
      if(timerBar <= 0)
      {
        //onSubmit();
        console.log(timerBar.toFixed(3));
        alert("Baiges laikas");
        clearIntervals();
      }
    }, 1000);
    
    timerStyleRef.current = setInterval(() => document.getElementById("progressBar").style.width = timerBar.toFixed(3) + '%', 1000);

    return clearIntervals;
  })

    return (
      <div className="test-page">
        <Link to="/" onClick={clearIntervals}> 
            <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
      <Box>
        <div className="test-page--progress">
          <div className="test-page--progress-track">
            <div className="test-page--progress-track-filler" id="progressBar"></div>
          </div>
          <div className="tar">
            <p id="CTA">LAIKAS</p>
            <div className="test-page--progress-value">
                <p id="body">
                    <TimeElapsed startDate={startTime}/>
                </p>
            </div>
          </div>
        </div>
        <Formik
          component={() => {
            return (
              <Form>
                <div className="text-container">
                  <h5>2.	Dabartinė Europos Sąjunga vienija 27 valstybes nares. Kuri valstybė 2013 metais tapo naujausia Europos Sąjungos nare, 2023 m. sausio 1 d. prisijungė prie euro zonos, o šios šalies vėliava mums gali priminti šachmatų žaidimą?</h5>
                </div>

                <div className="test-page--assets-wrapper">
                    <div className="test-page--assets">
                      <img src={media2kl} alt="klausimo paveikslelis"/>
                    </div>
                </div>

                <div className="test-page--questions">
                      <Field
                        component={radio}
                        required
                        name="answer"
                      >
                        <p id="body">a)	Prancūzija</p>
                      </Field>
                      <Field
                        component={radio}
                        required
                        name="answer"
                      >
                        <p id="body"> b)	Švedija</p>
                      </Field>
                      <Field
                        component={Checkbox}
                        required
                        name="answer"
                      >
                        <p id="body"> c)	Kroatija</p>
                      </Field>
                      <Field
                        component={Checkbox}
                        required
                        name="answer"
                      >
                        <p id="body"> d)	Turkija</p>
                      </Field>
                    
                </div>

                <div className="test-page--actions">

                  <ArrowToliau type="submit">
                    Kitas
                  </ArrowToliau>
                </div>
              </Form>
            )
          }}
          
        />
        </Box>
        </div>
    )

}
